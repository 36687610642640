import { Account, Org, Team__Account } from "@ollie-sports/models";
import { getServerHelpers, getUniversalHelpers } from "../../helpers";
import { validateToken } from "../../internal-utils/server-auth";
import express from "express";
import _ from "lodash";

export async function account__server__getAffiliatedOrgIdsAndTeamIds(p: { accountId: string }): Promise<{
  teamInfo: {
    teamId: string;
    teamAccount?: Team__Account;
    orgId?: string;
  }[];
  adminOrgIds: string[];
}> {
  // SERVER_ONLY_TOGGLE
  const { appOllieFirestoreV2: h } = getServerHelpers();
  const [teamsdata, adminOrgsdata] = await Promise.all([
    h.Team.query({ where: [{ accounts: { [p.accountId]: { exists: ["==", true] } } }] }),
    h.Org.query({ where: [{ accounts: { [p.accountId]: { exists: ["==", true] } } }] })
  ]);
  const teams = teamsdata.docs;

  return {
    teamInfo: teams
      .filter(t => !t.deletedAtMS)
      .map(t => {
        return { teamId: t.id, teamAccount: t.accounts[p.accountId], orgId: t.orgId };
      }),
    adminOrgIds: adminOrgsdata.docs.map(o => o.id)
  };
  // SERVER_ONLY_TOGGLE
}

account__server__getAffiliatedOrgIdsAndTeamIds.auth = async (r: express.Request) => {
  await validateToken(r);
};
// i18n certified - complete
